import React from 'react';
import background from '../images/mapleLeaves.jpg';
import '../styles/ContactUs.css';
import ContactForm from '../components/ContactForm';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import logo from '../images/Logo White PNG.png';
import phoneIcon from '../images/phoneIconContact.png';
import emailIcon from '../images/emailiconContact.png';
import locationIcon from '../images/locationIcon.png';
import Page from '../components/Page';

const ContactUs = () => {
  return (
    <Page title={'Dendro - Contact Us'}
    description={'Please contact us for more information, or fill out the form below and we will get back to you shortly.'}>
      <div className='contactContainer'>
        <div
          className='backgroundImage'
          style={{
            backgroundImage: `url(${background})`,
          }}
        ></div>

        <Card.Img
          variant='top'
          src={logo}
          alt='Dendro logo'
          className='logoContact'
          style={{ width: '60%', margin: 'auto' }}
        />
        <Card
          className='contactCard '
          style={{
            border: 'none',
          }}
        >
          <Card.Body>
            <h1
              style={{
                margin: 'auto',
                display: 'flex',
                justifyContent: 'space-around',
                marginBottom: '20px',
              }}
            >
              CONTACT US
            </h1>
            <Card.Text
              className='textContact'
              style={{
                margin: 'auto',
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              Please contact us for more information, or fill out the form below
              and we will get back to you shortly.
            </Card.Text>
          </Card.Body>
          <ListGroup className='list-group-flush'>
            <ListGroup.Item className='listItems bg-transparent'>
              <p>
                <img src={phoneIcon} alt='Telephone Icon' /> &nbsp; TELEPHONE:
                (931) 255 0875
              </p>
            </ListGroup.Item>
            <ListGroup.Item className='listItems bg-transparent'>
              <p>
                <img src={emailIcon} alt='Email Icon' /> &nbsp; EMAIL:
                info@dendrotreecare.com
              </p>
            </ListGroup.Item>
            <ListGroup.Item className='listItems bg-transparent'>
              <p>
                <img src={locationIcon} alt='Location Icon' /> &nbsp; LOCATION:
                Hermitage, TN 37076
              </p>
            </ListGroup.Item>
          </ListGroup>
        </Card>
        <ContactForm className='contactForm' />
      </div>
    </Page>
  );
};

export default ContactUs;
