import React from 'react';
import background from '../images/fallCanopy.jpg';
import '../styles/AboutUs.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import logo from '../images/Logo PNG.png';
import ISA from '../images/BCMA-400.png';
import TRAQ from '../images/TRAQ.jpg';
import coi from '../images/Dendro Tree Care - COI.pdf';
import Page from '../components/Page';

const AboutUs = () => {
  return (
    <Page
      title={'Dendro - About Us'}
      description={
        'With nearly 10 years of experience in the industry, Bo has developed a reputation for providing high-quality tree care services, tailored to the needs of each individual tree and client.'
      }
    >
      <div>
        <div className='aboutUsContainer'>
          <div
            className='backgroundImage'
            style={{
              backgroundImage: `url(${background})`,
              opacity: '0.4',
            }}
          ></div>
          <div className=''></div>
          <h1 className='titleAboutUs'>ABOUT US</h1>
          <Card
            className='textAboutUs'
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
          >
            <Card.Img
              variant='top'
              className='logoAboutUs'
              src={logo}
              alt='Logo Dendro Black'
            />
            <Card.Body>
              <p>
                Dendro Tree Care is a professional tree care company founded by
                Bo Arrington, an ISA Board Certified Master Arborist with a
                passion for trees and the environment. With nearly 10 years of
                experience in the industry, Bo has developed a reputation for
                providing high-quality tree care services that are tailored to
                the needs of each individual tree and client.
              </p>
              <p>
                Dendro Tree Care specializes in a wide range of tree care
                services, including tree pruning, plant health care, tree risk
                assessments and tree planting. They use the latest techniques
                and equipment to ensure that their work is safe, efficient, and
                effective.
              </p>
              <p>
                As an environmentally conscious company, Dendro Tree Care is
                committed to using sustainable practices in all aspects of their
                work. They prioritize tree preservation whenever possible and
                use organic fertilizers and treatments to promote the health and
                vitality of trees.
              </p>
              <p>
                Bo and his team at Dendro Tree Care are dedicated to providing
                exceptional customer service and building long-term
                relationships with their clients. They take the time to listen
                to their clients' needs and concerns and provide expert advice
                and recommendations to help them make informed decisions about
                their trees.
              </p>
              <p>
                Dendro Tree Care is a company that is passionate about trees,
                the environment, and providing the highest quality tree care
                services to their clients.
              </p>
            </Card.Body>
            <div className='qualDiv'>
              <div>
                <Card.Img
                  variant='top'
                  src={ISA}
                  alt='Board Certified Master Arborist logo'
                  className='qualImage'
                />
                <Card.Text
                  className='masterNumber'
                  style={{ fontWeight: 'bold' }}
                >
                  SO-10487B
                </Card.Text>
              </div>
              <Card.Img
                alt='Tree Risk Assessment Qualification logo'
                src={TRAQ}
                className='qualImage'
              />
            </div>
          </Card>
          <Button
            className='coiButton'
            aria-label='Button to download the Certificate of Insurance'
          >
            <a
              aria-label='Button to download the Certificate of Insurance'
              href={coi}
              style={{ textDecoration: 'none', color: 'black' }}
              target='_blank'
              rel='noreferrer'
              className='buttonText'
            >
              View/Download our Certificate of Insurance
            </a>
          </Button>
        </div>
      </div>
    </Page>
  );
};

export default AboutUs;
