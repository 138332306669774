import React from 'react';
import background from '../images/trees4.jpg';
import '../styles/Consulting.css';
import Card from 'react-bootstrap/Card';
import consulting from '../images/tree risk3.jpg';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import risk from '../images/fallenTree.jpg';
import Page from '../components/Page';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Consulting = () => {
  // scroll to respective part of page
  const riskRef = useRef(null);
  const siteEvalRef = useRef(null);

  const navbarHeight = 100;

  const treeRiskClick = () => {
    const offset = riskRef.current?.offsetTop - navbarHeight;
    window.scrollTo({ top: offset, behavior: 'smooth' });
  };

  const siteEvalClick = () => {
    const offset = siteEvalRef.current?.offsetTop - navbarHeight;
    window.scrollTo({ top: offset, behavior: 'smooth' });
  };

  return (
    <Page
      title={'Dendro - Consulting Services'}
      description={
        'Our team of expert arborists will assess the health and condition of your trees, identifying any potential risks or issues.'
      }
    >
      <div className='consultingContainer'>
        <div
          className='backgroundImage'
          style={{
            backgroundImage: `url(${background})`,
            opacity: '0.4',
          }}
        ></div>
        <h1 className='titleConsulting'>CONSULTING</h1>
        <Card className='textConsulting'>
          <Card.Img
            variant='top'
            src={consulting}
            style={{ opacity: '0.9' }}
            alt='Fallen Tree in the middle of a trail/road in the woods'
          />
          <Card.Body>
            <p>
              Are you concerned about the health and well-being of the trees on
              your property? Do you want to ensure that they are properly
              maintained and cared for?
            </p>
            <p>
              Our team of expert arborists will assess the health and condition
              of your trees, identifying any potential risks or issues. We'll
              provide you with a detailed report outlining our findings and
              recommendations, including advice on pruning, fertilization, and
              pest management.
            </p>
            <p>
              By investing in our tree consultation services, you'll not only be
              ensuring the longevity of your trees but also promoting the
              overall health and sustainability of your landscape.
            </p>
            <div>
              <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                <li>
                  <Link
                    aria-label='learn more about Tree Risk'
                    onClick={treeRiskClick}
                    to='#risk'
                    className='linksConsulting'
                  >
                    Tree Risk Assessments
                  </Link>
                </li>
                <li>
                  <Link
                    aria-label='learn more about Site Evaluations'
                    onClick={siteEvalClick}
                    to='#siteEval'
                    className='linksConsulting'
                  >
                    Tree Inventories and Site Evaluations
                  </Link>
                </li>
              </ul>
            </div>
          </Card.Body>
        </Card>
        <div>
          <h2 className='titleConsulting' id='risk' ref={riskRef}>
            Tree Risk Assessments
          </h2>
          <LazyLoadImage
            className='riskImage'
            variant='top'
            src={risk}
            alt='A Fallen Tree that broke a fence at a property line'
          />

          <Card className='textConsulting'>
            <Card.Body>
              <p>
                Are you concerned about the safety of your trees? Do you worry
                about the risk of falling branches or even entire trees that
                could damage property or harm people? If so, a tree risk
                assessment is the solution you need.
              </p>
              <p>
                Our tree risk assessment service provides a comprehensive
                evaluation of the health and safety of your trees. Our team of
                certified arborists will conduct a thorough inspection of your
                trees, assessing their structural integrity, identifying any
                signs of disease or pest infestation, and evaluating their
                overall health.
              </p>
              <p>
                Using the latest technology and industry best practices, we'll
                provide you with a detailed report that outlines any potential
                risks associated with your trees and recommends appropriate
                action to mitigate those risks. This could include pruning, tree
                removal, or other tree care services.
              </p>
              <p>
                Investing in a tree risk assessment not only ensures the safety
                of your property and loved ones, but it can also save you money
                in the long run by preventing costly damage from falling trees
                or branches. All of our assessors hold Tree Risk Assessment
                Qualifications (TRAQ) by the International Society of
                Arboriculture.
              </p>
              <p>
                Don't wait until it's too late - schedule a tree risk assessment
                with us today and enjoy peace of mind knowing that your trees
                are healthy and safe.
              </p>
            </Card.Body>
          </Card>
        </div>
        <div>
          <h2 className='titleConsulting' id='siteEval' ref={siteEvalRef}>
            Tree Inventories and Site Evaluations
          </h2>
          <Card className='textConsulting'>
            <Card.Body>
              <p>
                Are you a property owner or manager looking to optimize your
                land use and manage your trees efficiently?
              </p>
              <p>
                Our team of experts will conduct a thorough inventory of all the
                trees on your property, including species, size, condition, and
                location. We will also evaluate the site's soil type,
                topography, and other environmental factors that can affect tree
                growth and health. With this information, we can provide you
                with a detailed report and management plan that will help you
                make informed decisions about your trees.
              </p>
              <p>
                Our tree inventories and site evaluations can benefit you in
                several ways. First, they can help you identify any hazardous
                trees that need to be removed or trimmed to prevent property
                damage or personal injury. Second, they can help you prioritize
                tree care tasks and allocate your resources more efficiently.
                Third, they can help you plan for future tree planting and
                ensure that you select the right species for your site.
              </p>
              <p>
                Investing in our tree inventories and site evaluations is a
                smart decision that will pay off in the long run. By managing
                your trees effectively, you can increase your property value,
                enhance your curb appeal, and create a safer and healthier
                environment for your community.
              </p>
              <p>
                Contact us today to schedule your tree inventory and site
                evaluation and take the first step towards better tree
                management!
              </p>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Page>
  );
};

export default Consulting;
