import React from 'react';
import background from '../images/trees4.jpg';
import '../styles/IntegratedPestManagement.css';
import Card from 'react-bootstrap/Card';
import bug from '../images/integratedPest.jpg';
import Page from '../components/Page';

const IntegratedPestManagement = () => {
  return (
    <Page
      title={'Dendro - Integrated Pest Management'}
      description={
        'Our program utilizes Integrated Pest Management (IPM) techniques, which means we take a holistic approach to pest control.'
      }
    >
      <div className='IPMContainer'>
        <div
          className='backgroundImage'
          style={{
            backgroundImage: `url(${background})`,
            opacity: '0.4',
          }}
        ></div>
        <h1 className='titleIPM'>INTEGRATED PEST MANAGEMENT</h1>
        <Card className='textIPM'>
          <Card.Img
            variant='top'
            src={bug}
            alt='Image of a ladybug eating another pest on top of a flower'
            style={{ opacity: '0.9' }}
          />
          <Card.Body>
            <p>
              Welcome to our Tree and Shrub IPM Program! If you're looking for a
              comprehensive and effective way to protect your trees and shrubs,
              you've come to the right place.
            </p>
            <p>
              Our program utilizes Integrated Pest Management (IPM) techniques,
              which means we take a holistic approach to pest control. Instead
              of just spraying chemicals, we focus on preventing and managing
              pests by using a combination of strategies, such as:
            </p>
            <ul className='ulBullets'>
              <li>Monitoring and scouting for pests and diseases</li>
              <li>Using natural predators and biological controls</li>
              <li>
                Applying targeted and low-impact pesticides only when necessary
              </li>
            </ul>
            <p>
              By using IPM, we can help keep your trees and shrubs healthy and
              beautiful while minimizing the impact on the environment.
            </p>
            <p>
              Here are some of the benefits of our Tree and Shrub IPM Program:
            </p>
            <ul className='ulBullets'>
              <li>
                Protect your investment: Trees and shrubs are valuable assets
                that add beauty and value to your property. Our program can help
                protect your investment by keeping them healthy and free of
                pests and diseases.
              </li>
              <li>
                Improve curb appeal: A well-maintained landscape can enhance the
                curb appeal of your property, making it more attractive to
                potential buyers or renters.
              </li>
              <li>
                Increase property value: By keeping your trees and shrubs
                healthy and beautiful, you can increase the value of your
                property. Studies have shown that landscaping can increase
                property values by up to 20 percent.
              </li>
              <li>
                Reduce environmental impact: Our program focuses on using
                targeted and low-impact pesticides, which means we can minimize
                the impact on the environment while still effectively managing
                pests and diseases.
              </li>
              <li>
                Peace of mind: With our Tree and Shrub IPM Program, you can rest
                assured that your landscape is in good hands. Our trained and
                certified professionals will work with you to create a
                customized plan that meets your specific needs and goals.
              </li>
            </ul>
            <p>
              Contact us today to learn more about our Tree and Shrub IPM
              Program and start enjoying the benefits of a healthy and beautiful
              landscape!
            </p>
          </Card.Body>
        </Card>
      </div>
    </Page>
  );
};

export default IntegratedPestManagement;
