import React from 'react';
import background from '../images/trees4.jpg';
import '../styles/PlantHealth.css';
import Card from 'react-bootstrap/Card';
import plantHealth from '../images/branches.webp';
import { Link } from 'react-router-dom';
import soil from '../images/soil3.jpg';
import pest from '../images/ladybugEatingBug.jpg';
import { useRef } from 'react';
import Page from '../components/Page';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const PlantHealth = () => {
  const pestRef = useRef(null);
  const soilRef = useRef(null);

  const navbarHeight = 100;

  const handlePestClick = () => {
    const offset = pestRef.current?.offsetTop - navbarHeight;
    window.scrollTo({ top: offset, behavior: 'smooth' });
  };

  const handleSoilClick = () => {
    const offset = soilRef.current?.offsetTop - navbarHeight;
    window.scrollTo({ top: offset, behavior: 'smooth' });
  };

  return (
    <Page
      title={'Dendro - Plant Health Services'}
      description={
        'Trees provide many valuable ecosystem services, including air and water purification, carbon sequestration, erosion control, and habitat for wildlife.'
      }
    >
      <div className='plantHealthContainer'>
        <div
          className='backgroundImage'
          style={{
            backgroundImage: `url(${background})`,
            opacity: '0.4',
          }}
        ></div>

        <div className='titleBoxPlantHealth'></div>
        <h1 className='titlePlantHealth'>PLANT HEALTH SERVICES</h1>
        <Card className='textPlantHealth'>
          <Card.Img
            variant='top'
            src={plantHealth}
            alt='Small tree and branches with green leaves and the sun penetrating behind them'
            style={{ opacity: '0.7' }}
            width='auto'
            height='auto'
          />
          <Card.Body>
            <p>
              Maintaining tree health is important for a variety of reasons,
              including environmental, economic, and social benefits. Trees
              provide many valuable ecosystem services, including air and water
              purification, carbon sequestration, erosion control, and habitat
              for wildlife. Here are some specific benefits of maintaining tree
              health:
            </p>
            <ul>
              <li>
                Reduced risk of pest infestations and diseases: Healthy trees
                are more resistant to pest infestations and diseases, which can
                save money on pest control treatments and prevent the need for
                tree removal.
              </li>
              <li>
                Increased property values: Trees can enhance the aesthetic value
                of properties and increase their market value, especially when
                they are healthy and well-maintained.
              </li>
              <li>
                Improved air and water quality: Trees play an important role in
                removing pollutants from the air and water, which can have a
                positive impact on human health and the environment.
              </li>
              <li>
                Enhanced wildlife habitat: Trees provide food, shelter, and
                nesting sites for many species of wildlife, which can help to
                maintain biodiversity and support ecological balance.
              </li>
              <li>
                Reduced energy costs: Trees can provide shade and reduce the
                amount of solar radiation that enters buildings, which can lower
                cooling costs during hot weather.
              </li>
              <li>
                Improved community health and well-being: Trees can have a
                positive impact on mental and physical health, providing
                opportunities for outdoor recreation and reducing stress.
              </li>
              <li>
                Increased resilience to extreme weather events: Healthy trees
                are better able to withstand extreme weather events, such as
                droughts, floods, and storms, which can help to prevent property
                damage and save money on tree removal and replacement.
              </li>
            </ul>
            <p>
              Maintaining tree health is crucial for preserving the many
              benefits that trees provide to our environment, economy, and
              communities. Through proper care and maintenance, we can ensure
              that our trees remain healthy and continue to provide these
              valuable services for generations to come.
            </p>
            <div>
              <ul
                style={{
                  listStyleType: 'none',
                  paddingLeft: '0',
                }}
              >
                <li>
                  <Link
                    aria-label='link to read more about pest management'
                    to='#pest'
                    className='linksPlantHealth '
                    onClick={handlePestClick}
                  >
                    Pest Management
                  </Link>
                </li>
                <li>
                  <Link
                    aria-label='link to read more about soil care'
                    to='#soilCare'
                    className='linksPlantHealth'
                    onClick={handleSoilClick}
                  >
                    Soil Management and Fertilization
                  </Link>
                </li>
              </ul>
            </div>
          </Card.Body>
        </Card>
        <h2 className='titlePlantHealth' id='pest' ref={pestRef}>
          Pest Management
        </h2>
        <LazyLoadImage
          className='plantHealthImages'
          variant='top'
          src={pest}
          alt='Ladybug eating a pest, as a form of Integrated Pest Management'
          style={{ opacity: '0.95' }}
        />

        <Card className='textPlantHealth'>
          <Card.Body>
            <p>
              Tree pests can cause a range of problems for trees, from unsightly
              cosmetic damage to severe structural damage that can threaten the
              health and stability of the entire tree. Some of the problems
              associated with tree pests include:
            </p>
            <ul>
              <li>
                Reduced growth and productivity: Many tree pests attack the
                leaves, branches, and roots of trees, which can cause a
                reduction in growth and productivity. This can lead to stunted
                growth, smaller fruit yields, and decreased timber quality.
              </li>
              <li>
                Damage to bark and wood: Some tree pests, such as bark beetles,
                bore into the bark and wood of trees, causing extensive damage.
                This can weaken the tree and make it more susceptible to disease
                and other pests.
              </li>
              <li>
                Spread of diseases: Tree pests can also act as vectors for
                diseases that can affect trees. For example, the emerald ash
                borer spreads a deadly disease called ash yellows that can kill
                ash trees.
              </li>
              <li>
                Death of trees: In some cases, tree pests can cause the death of
                trees. For example, the Dutch elm disease, spread by the elm
                bark beetle, has caused the death of millions of elm trees
                across North America.
              </li>
              <li>
                Economic impacts: Tree pests can have significant economic
                impacts, particularly in the forestry and agriculture
                industries. Damage to trees can result in decreased crop yields,
                decreased timber quality, and increased costs for pest
                management.
              </li>
              <li>
                Environmental impacts: Tree pests can also have significant
                environmental impacts. For example, the loss of trees can result
                in decreased air and water quality, increased erosion, and loss
                of wildlife habitat.
              </li>
            </ul>
            <p>
              It is important to properly identify and manage tree pests in
              order to minimize their impacts on trees and surrounding
              ecosystems. If you suspect you have a pest problem on your trees
              or shrubs, give us a call today!
            </p>
          </Card.Body>

          <div style={{ paddingLeft: '10px' }}>
            <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
              <li>
                <Link
                  aria-label='link to read more about common pests in our area'
                  to='/pestManagement'
                  className='linksPlantHealth '
                  style={{ color: 'black', fontWeight: 'bold' }}
                >
                  Common Pests
                </Link>
              </li>
              <li>
                <Link
                  aria-label='link to read more about integrated pest management'
                  to='/integratedPestManagement'
                  className='linksPlantHealth '
                  style={{ color: 'black', fontWeight: 'bold' }}
                >
                  Integrated Pest Management
                </Link>
              </li>
            </ul>
          </div>
        </Card>
        <h2 className='titlePlantHealth' id='soilCare' ref={soilRef}>
          Soil Management and Fertilization
        </h2>
        <LazyLoadImage
          className='plantHealthImages'
          variant='top'
          src={soil}
          alt='Soil on a planting box with some grass growing in it'
          style={{ opacity: '0.9' }}
        />

        <Card className='textPlantHealth'>
          <Card.Body style={{ marginBottom: '40px' }}>
            <p>
              At Dendro Tree Care, we understand the vital role that soil plays
              in the health and longevity of your trees. Our soil management and
              fertilization services are designed to ensure your trees receive
              the essential nutrients they need to thrive. Our team of experts
              will assess the specific needs of your soil and trees, and tailor
              a comprehensive soil care plan to enhance root development,
              improve nutrient uptake, and promote overall tree vitality. With
              our commitment to sustainable and environmentally responsible
              practices, you can trust us to safeguard your trees' long-term
              health while preserving the natural beauty of your landscape.
              Choose Dendro Tree Care for soil management and fertilization that
              yields lasting results.
            </p>
          </Card.Body>

          <div
            style={{
              paddingLeft: '10px',
              paddingBottom: '10px',
              // display: 'flex',
              // justifyContent: 'space-around',
            }}
          >
            <Link
              aria-label='link to read more about soil care'
              to='/soilCare'
              className='linksPlantHealth '
              style={{ color: 'black', fontWeight: 'bold' }}
            >
              Learn More
            </Link>
          </div>
        </Card>
      </div>
    </Page>
  );
};

export default PlantHealth;
