import React from 'react';
import background from '../images/trees4.jpg';
import '../styles/Pruning.css';
import Card from 'react-bootstrap/Card';
import pruning from '../images/treeclimb.jpg';
import Page from '../components/Page';

const Pruning = () => {
  return (
    <Page
      title={'Dendro - Pruning'}
      description={
        'Tree pruning is an essential aspect of tree care that involves selectively removing branches and limbs to improve tree health, promote growth, and enhance the appearance of your landscape.'
      }
    >
      <div className='pruningContainer'>
        <div
          className='backgroundImage'
          style={{
            backgroundImage: `url(${background})`,
            opacity: '0.4',
          }}
        ></div>
        <h1 className='titlePruning'>PRUNING</h1>
        <Card className='textPrunning'>
          <Card.Img
            variant='top'
            src={pruning}
            style={{ opacity: '0.9' }}
            alt='Pruning a Japanese Maple'
          />
          <Card.Body>
            <p>
              Are you tired of unsightly, overgrown trees that are causing
              problems in your yard? Do you want to improve the health and
              appearance of your trees while also enhancing the beauty of your
              property?
            </p>
            <p>
              Tree pruning may be just what your property needs. Tree pruning is
              an essential aspect of tree care that involves selectively
              removing branches and limbs to improve tree health, promote
              growth, and enhance the appearance of your landscape. Our team of
              experienced and highly skilled arborists will carefully assess
              your trees and determine the best pruning techniques to achieve
              your goals.
            </p>
            <p>
              By investing in tree pruning, you can prevent potential hazards
              such as falling branches, improve tree structure and stability,
              increase sunlight penetration, and enhance air circulation. This
              can not only make your yard look more beautiful and
              well-maintained, but it can also increase the value of your
              property.
            </p>
            <p>
              We use the latest research and techniques to ensure that our tree
              pruning services are efficient, safe, and effective. We take great
              pride in our ability to maintain the natural beauty and integrity
              of your trees while enhancing their structural strength. Plus, we
              always clean up the area after the job is done, leaving your yard
              looking clean and tidy.
            </p>
            <p>
              Don't wait until your trees are causing problems to take action.
              Contact us today to schedule your tree pruning service and enjoy
              the benefits of healthier, more beautiful trees!
            </p>
          </Card.Body>
        </Card>
      </div>
    </Page>
  );
};

export default Pruning;
